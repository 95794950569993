:root {
  --wds-color-black: #111111;
  --wds-color-black-1: #171717;
  --wds-color-black-2: #4A4A4A;
  --wds-color-black-3: #4F545A;
  --wds-color-black-4: #2e2e2e;
  --wds-color-black-5: #414143;
  --wds-color-white: #ffffff;
  --wds-color-white-1: #F1F1F1;
  --wds-color-gray: #E7E7E7;
  --wds-color-gray-2: #7C848C;
  --wds-color-gray-3: #4F545A;
  --wds-color-gray-4: #F2F4F6;
  --wds-color-gray-5: #F6F8F9;
  --wds-color-gray-6: #F5F5F5;
  --wds-color-blue: #0074FC;
  --wds-color-blue-2: #0067C7;
  --wds-color-blue-3: #0050BB;
  --wds-color-blue-4: #1E6CF6;
  --wds-color-green: #05BABE;
  --wds-color-green-2: #069396;
  --wds-color-red: #DE350B;
  --wds-color-red-1: #f26538;
  --wds-color-blue-transparent: rgba(35, 213, 252, 0.64);
  --wds-text-primary: var(--wds-color-black);
  --wds-text-secondary: var(--wds-color-black-3);
  --wds-text-invesre: var(--wds-color-white);
  --wds-text-hint: var(--wds-color-gray-2);
  --wds-controls-primary-default: var(--wds-color-blue);
  --wds-controls-primary-hover: var(--wds-color-blue-2);
  --wds-controls-primary-down: var(--wds-color-blue-3);
  --wds-controls-primary-green-default: var(--wds-color-green);
  --wds-controls-primary-green-hover: var(--wds-color-green-2);
  --wds-controls-primary-green-down: var(--wds-color-green-2);
  --wds-border-inputs-dividers: var(--wds-color-gray);
  --wds-border-focus: var(--wds-color-blue-transparent);
  --wds-dd-search-hover: var(--wds-color-gray);
  --wds-dd-search-active: var(--wds-color-gray);
  --wds-controls-secondary-default: var(--wds-color-black);
  --wds-controls-secondary-hover: var(--wds-color-black-2);
  --wds-controls-secondary-down: var(--wds-color-black-2);
  --wds-controls-secondary-bg-hover: var(--wds-color-black);
  --wds-controls-secondary-bg-down: var(--wds-color-black-5);
  --wds-controls-secondary-bg: var(--wds-color-white);
  --wds-controls-secondary-border-inputs: var(--wds-color-gray);
  --wds-controls-secondary-inversion: var(--wds-color-white);
  --wds-controls-secondary-inversion-hover: var(--wds-color-black);
  --wds-controls-secondary-inversion-down: var(--wds-color-black);
  --wds-controls-secondary-inversion-blue-hover: var(--wds-color-blue-4);
  --wds-controls-secondary-inversion-blue-down: var(--wds-color-blue-4);
  --wds-controls-secondary-inversion-bg-hover: var(--wds-color-white);
  --wds-controls-secondary-inversion-bg-down: var(--wds-color-white-1);
  --wds-system-error: var(--wds-color-red);
  --wds-system-error-color: var(--wds-color-white);
  --wds-bg-body-block: var(--wds-color-gray-4);
  --wds-bg-body-block-2: var(--wds-color-gray-5);
  --wds-bg-body-block-secondary: var(--wds-color-white);
  --wds-bg-body-block-hover: var(--wds-color-gray-6);
  --wds-controls-secondary-default_dark: var(--wds-color-white);
  --wds-controls-secondary-bg_dark: var(--wds-color-black-1);
  --wds-controls-secondary-border-inputs__dark: var(--wds-color-black-4);
  --wds-system-error_dark: var(--wds-color-red-1);
  --wds-dd-search-hover_dark: var(--wds-color-black-4);
  --wds-dd-search-active_dark: var(--wds-color-black-4)
}